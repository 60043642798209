
%left-panel-toggle {
    display: block;
    height: 57px;
    min-height:57px;
    padding: 13px 16px 0;
    color: $charcoal-800;
    @include font-size(20px);
    text-align: left;
    background-color: $surface-darker;
    z-index: 1;
    cursor: pointer;
    position:relative;
    border-bottom: solid 1px $charcoal-300;
}

.hidden-scroll {
    display: none;
}

.left-panel {
    background-color: $surface-base;
    border-right: solid 1px $charcoal-300;
    border-bottom: solid 1px $charcoal-300;
    width: 248px;
    margin-top: -20px;
    z-index: 8;
    @include transition(all 0.35s ease);
    &.collapsed {
        width: 61px;
        border-bottom: none;
        .nav-label,
        .left-panel-menu__filter > li {
            display: none;
        }
        .left-panel-menu {
            display: none;
        }
    }
    &.scrollBottom {
        bottom: 70px;
        @include transition(all 0s ease);
    }
}

.panel-flyout {
    background-color: $surface-base;
    border-right: solid 1px $charcoal-300;
    border-bottom: solid 1px $charcoal-300;
    width: 280px;
    flex: 0 0 280px;
    margin-top: -20px;
    @include transition(all 0.35s ease);

    .panel-flyout-content {
        position: relative;
        padding: 1rem;
    }

    &.panel-close {

        -webkit-transform: translate(0%);
        -ms-transform: translate(0%);
        transform: translate(0%);
        margin-left: -300px;
    }

    &.panel-open {
        margin-left: 0;

        .filter-close {
            display: inline-block;
        }

        .filter-list-options {
            position: relative; 
            min-height: 100px
        }
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        top: auto;
		left: 0;
		width: 100%;
        height: calc(100% - 56px);
        z-index: 1050;
        display: flex;
        flex-direction: column;

        .panel-flyout-content {
            flex-grow: 1;
            min-height: 0;
            overflow: auto;
        }

        .filter-close {
            display: none;
        }
        
        select:not(.form-control),
        select:not(.form-control) + .ui-widget {
            width: calc(100% - 40px) !important;
        }

        &.panel-close {
            margin-left: -100%;
        }

        &.panel-open {
            margin-left: 0;

            .filter-action-btns {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 75px;
                z-index: 1;
                justify-content: center;
                align-items: center;
                background-color: $surface-darker;
                display: flex;
            }

            .has-filters-selected {
                padding-bottom: 90px;
            }
    
            .filter-close {
                display: inline-block;
            }

            .report-intro-mobile {
                margin-bottom: 0.5rem;
            }
        }
    }
}

.mb-panel, 
.panel-flyout {

    select:not(.form-control),
    select:not(.form-control) + .ui-widget {
        width: 245px !important;

        &.ui-datepicker-year {
            width: inherit !important;
        }
    }

    .mb-label-group select:not(.form-control) {
        width: 100% !important;
    }
}

.mb-filter-action-btns {
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 1rem;

    @include media-breakpoint-up(lg) {
        .mb-filter-show-results-btn  {
            display: none;
        }
    }
}

.panel-toggle, .panel-toggle-internal {
    @extend %left-panel-toggle;
    &:hover,
    &:focus {
        background-color: $light-gray;
        color: $charcoal-975;
    }
    &.centered {
        background-color: $surface-dark;
        width: 60px;
    }
    a {
        color: $charcoal-500;
    }
}

.panel-toggle-internal {
    margin-top: -20px;
    margin-right: -60px;
    position: absolute;
    border-right: solid 1px $charcoal-300;
    z-index: 0;
}

.action-panel-highlight-container {
    box-sizing: border-box;
    border-bottom: 1px solid $blue-light-3;
    border-top: 1px solid $blue-light-3;
    background-color: $blue-light-4;
    width: 100%;
    padding: 0.75rem 0.75rem 0.25rem 0.75rem;

        p {
            color: $primary-color;
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
            margin: 0;
        }
}

.filter-panel-search-input {
        width: 100%;
        line-height: 28px;
        padding: 0 4px;
        font-weight: 300;
}

.filter-panel-search-input-icon,
.search-input-icon {
    position: absolute;
    top: 5px;
    right: 3px;
    color: $charcoal-500;
    font-size: 21px !important;
    cursor: pointer;
}

.right-panel {
    background-color: $surface-base;
    border-left: solid 1px $charcoal-300;
    border-bottom: solid 1px $charcoal-300;
    margin-top: -21px;
    width: 325px;
    z-index: 8;
    @include transition(all 0.35s ease);
    display: block;

    &.action-panel {
        margin-top: 0;
    }

    &.panel-close {
        width: 0;

        #actionPanelContent {
            display: none;
        }
    }

    &.panel-open {

        .right-panel-toggle {
            display: block;
        }
        
        #actionPanelContent {
            display: block;
        }
    }

    &.panel-close .right-panel-toggle {
        display: none;
    }

    &.collapsed-secondary {
        width: 61px;
        border-bottom: none;

        .nav-label-secondary {
            display: none;
        }
    }
    .action-panel-close {
        display: none;
    }

    @include media-breakpoint-down(md) {
        display: none; 

        &.panel-open {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            z-index: 70101;
            border-left: none;
            display: block;
            padding-bottom: 100px;
        }

        .action-panel-close {
            display: inline-block !important;
            position: absolute;
            right: 9px;
            top: 24px;
            font-size: 16px !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .flex-panel-table {
        margin-bottom: 1.5rem;
    }
}

#mobileActionPanelBar {
    height: 100px;
}

.header-dropdown-container {
    border-top: solid 1px $charcoal-300;

    &:first-child {
        border-top: none;
    }
}

.content-drop-group {
    padding: 0.65rem 0;
    position: relative;

    .required-field & {
        .content-drop-title-text {
            position: relative; 
            padding-right: 0.75rem;

            &:before {
                color: $red-dark-1;
                font-weight: bold;
                content: '*';
                @include font-size(13px);
                position: absolute;
                right: 0;
            }
        }
    }

    .required-field.invalid-input & {
        .content-drop-title-link {
            color: $red-dark-1;
        }
    }

    .content-drop {
        > .content-drop-title {
            border-bottom: 1px solid $charcoal-500;
            font-size: 0.75rem;

            &.d-flex .content-drop-title-link {
                flex: 1;
            }

            a,
            span.drop-title,
            button,
            h3 {
                text-transform : uppercase;
                color : $charcoal-500;
                font-size: inherit;
                text-align: left;
                padding: 0;
                text-decoration: none;

                &:hover {
                    text-decoration : none;
                }

                &.content-drop-title-link {
                    display: flex;

                    &:not(.no-toggle) {
                        &:before {
                            color : $charcoal-500;
                            font-family: 'fontawesome';
                            content: "\f0da";
                            margin-right: 0.25rem;
                            display: inline-block;
                            width: 10px;
                        }
                    }
                }
            }

            h3,
            h3 > button {
                margin-bottom: 0;
                text-transform: none;
                @include font-size(22px);
                color: $charcoal-900;
            }

            h3 > button {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                width: 100%;

                &:hover .content-drop-title-text {
                    text-decoration: underline;
                }

                .material-icons {
                    @include font-size(24px, true);
                    color: $charcoal-900 !important;
                }
            }

            div {
                text-transform: uppercase;
                color: $charcoal-500;
            }
        }
        
        > .drop-content {
            max-height : 0;
            opacity: 0;
            height: auto;
            overflow: hidden;
            @include transition(all 0.5s linear);

            > ul {
                padding: 0;
                margin-bottom: 0;
            }
        }

        > .drop-select > button.ui-widget {
            width: 276px !important;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }

        &:not(.open) {
            > .drop-content {
                max-height: 0 !important;
            }
        }

        &.open {
            > .content-drop-title {
                .content-drop-title-link:not(.no-toggle) {
                
                    &:before {
                        content: "\f0d7";
                    }
                }
            }
            
            > .drop-content {
                max-height : 500px;
                overflow: initial;
                opacity: 1;
                overflow-y: auto;
                padding: 0.75rem 0;

                &.max-250 {
                    max-height: 250px !important;
                }

                &.no-y-overflow {
                    overflow-y: inherit !important;
                }

                .filter-list-options & {
                    max-height: 200px;
                    padding-bottom: 0;
                }

                .last-purchase-date-container & {
                    max-height: 400px;
                }

                &.initial-height {
                    max-height: initial;
                }
            }

            .drop-content-scroll {
                max-height: 200px;
                overflow: initial;
                opacity: 1;
                overflow-y: auto;
            }

        }
    }
}

.collapsable {
    .content {
        @include transition(0.35s ease);
    }
    .expand {
        display: none !important;
    }
    &.collapsed {
        width: 61px !important;
        overflow: hidden !important;

        .content,
        .nav-label {
            display: none;
        }
        .expand {
            display: block !important;
        }
        .hide {
            display: none !important;
        }
    }
}

.has-no-y-overflow.drop-content { 
    overflow-y: inherit !important;
}

#cart.collapsable {
    margin-right: -15px; /* offset mainView */
}

.nav-label-main, 
.nav-label-main-secondary {
    color: $charcoal-600;
    padding-left: 4px;
    text-transform: uppercase;
    @include font-size(14px);
    font-weight: 500;
    line-height: calculateRem(14px);
}

.nav-menu {
    margin-left: 10px;
}

#filterSidebar {
    
    .fa-filter {
        margin-left: 10px;
    }

    &.collapsed .fa-filter {
        margin-left: 0;
    }
}

.noMargin {
    margin-left: 0;
}

.left-panel-toggle {
    @extend %left-panel-toggle;
    &:hover,
    &:focus {
        background-color: $light-gray;
        color: $charcoal-975;
    }
    &.centered {
        background-color: $surface-dark;
        width: 60px;
    }
    a {
        color: $charcoal-500;
    }
}

.right-panel-toggle {
    @extend %left-panel-toggle;
    cursor: default !important;
    &:hover,
    &:focus {
        background-color: $light-gray;
        color: $charcoal-975;
    }
    a {
        color: $charcoal-500;
    }
    @include media-breakpoint-down(md) {
        &.collapsable {
            cursor: pointer !important;
        }
    }
}

#adminPanel {
    @include transition(0.35s ease);
    .left-panel.collapsed .left-panel-toggle {
        border-right: solid 1px $gray;
        border-bottom: none;
    }
}

.left-panel-menu {
    padding: 0;
    list-style: none;
    li {
        padding: 0;
        list-style: none;
        a {
            display: block;
            padding: 10px 18px;
            text-align: left;
            border-bottom: solid 1px $gray;
            color: $charcoal-800;
            &:hover {
                background-color: $light-gray;
                text-decoration: none;
                color: $charcoal-975;
            }
        }
    }
}

.main-content {
    margin-top: 60px;
    margin-left: 248px;
    margin-right: 248px;
    padding: 20px;
    transition: 0.4s ease;
}

.main-right {
    margin-right: 60px !important;
}

.centered {
    text-align: center;
    padding-top: 16px;
}

.chev-right {
    position: absolute;
    right: 8px;
    top: 24px;
    @include font-size(16px, true);
    color: $charcoal-600;
}

.filter-close {
    display: none;
    position: absolute;
    right: 9px;
    top: 24px;
    font-size: 16px !important;
    color: $charcoal-600;
}

.active-flyouttab {
    background-color: $gray;
    &:hover {
        background-color: $gray !important;
    }
}

  /* Item Search Filter */

.left-panel-menu__filter {
    padding: 16px;
    > li {
        width: 100%;
        padding-bottom: 24px;
        position: relative;
        &:last-child {
            padding-bottom: 0;
        }
    }
}


#applyFilters {
    display: none;
}

/* --------------- NEW PANEL ---------------- */

/* ----- BOTH PANELS ----- */
.mb-panel {
    width: 272px;
    border: 1px solid $gray;
    border-top: none;
    border-left: none;
    @include media-breakpoint-up(lg) {
        @include transition(0.3s ease);
    }

    #cartPanel & {
        margin-right: -15px;
        width: 325px;

        &.analysis-panel-closed {
            @include transition(0s ease);
        }
    }

    .mb-filter-panel-action-container,
    .mb-panel-action-container {

        button {
            text-decoration: none;
            color: initial;
            border: none;
            border-radius: 0;
        }

        .panel-header-toggle-btn {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            width: 100%;
        }
    }

    .mb-large-panel & {
        width: 350px;
    }

    .panel-header,
    .panel-header-toggle-btn {
        position: relative;
        z-index: 1;
        overflow-y: hidden;
    }

    .panel-header,
    .panel-header-internal,
    .panel-header-toggle-btn,
    .panel-header-toggle-closed-btn {
        height: 57px;
        padding: 16px;
        background-color: $surface-darker;
        border-bottom: 1px solid $gray !important;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
        

        .panel-header-text {
            @include font-size(14px);
        }

        .panel-header-close {
            float: right;
            line-height: 26px;
            @include font-size(16px);
        }
    }

    .panel-header-internal,
    .panel-header-toggle-closed-btn {
        position: absolute;
        z-index: -1;
        overflow: hidden;
    }

    .mb-filter-panel-action-container .panel-header-toggle-closed-btn {
        border-right: solid 1px $gray !important;
    }

    .mb-panel-action-container .panel-header-toggle-closed-btn {
        border-left: solid 1px $gray !important;
    }

    .panel-content {
        background-color: $white;
    }

    &:not(.open):not(.mobile-open) {
        border-bottom: none;
        width: 0px !important;
        
        .panel-header,
        .panel-header-toggle-btn {
            z-index: 0;
            display: none;

            .panel-header-text,
            .panel-header-close {
                display: none;
            }
        }

        .panel-header-internal,
        .panel-header-toggle-closed-btn {
            z-index: 1;

            #overlayContentViewContainer & {
                width: 57px;
                position: relative;
            }
        }

        .panel-content {
            display: none;
        }
    }

    &:not(.open).hidden {
        display: none;
    }

    &.open .panel-header-internal,
    &.open .panel-header-toggle-closed-btn {
        #overlayContentViewContainer & {
            display: none;
        }
    }
}

.flex-content-with-panel {
    flex: 1;
    min-width: 0;
}

@include media-breakpoint-down(md) {
    .flex-panel-table .page-heading {
        padding-left: 70px;
        padding-right: 70px;

        .mb-overlay & {
            padding-right: 1rem;
        }
    }

    .page-heading-wrapper {

        &.has-panel .page-heading {
            padding-right: 70px;
        }
        
    }
}

.filter-panel-closed + .flex-panel-table .page-heading {
    padding-left: 70px;
}

.page-heading.analysis-panel-closed {
    padding-right: 70px;
}

.panel-container.analysis-panel-closed:not(.open) {
    padding-right: 57px;
}

@include media-breakpoint-down(md) {
    #cartPanel .panel-header-internal {
        right: -14px !important;
    }
}


/* ----- END BOTH ----- */

/* ----- LEFT / FILTER PANEL ----- */

.mb-panel.panel-left {

    @include media-breakpoint-down(md) {
        position: fixed;
        left: 0;
        top: 0;
        height: 95%;
        z-index: 1;
        border: none;

        .panel-header,
        .panel-header-toggle-btn {
            border-right: 1px solid $gray;
        }

        .panel-content {
            display: block;
            height: 100%;
            overflow: scroll;
        }

        &:not(.mobile-open) {
            position: relative;
            width: 0 !important;;

            .panel-header,
            .panel-header-toggle-btn {
                z-index: 0;
                width: 57px;
            }

            .panel-header-internal,
            .panel-header-toggle-closed-btn {
                z-index: 1;
            }

            .panel-header-text,
            .panel-header-close {
                display: none;
            }

            .panel-content {
                display: none;
            }
        }

        &.mobile-open {
            z-index: 72000;
            width: 100% !important;
        }
    }

    .panel-content {
        padding: 16px;
    }
}

/* ----- END LEFT / FILTER PANEL ----- */

/* ----- RIGHT / ACTION PANEL ----- */

@include media-breakpoint-up(md) {
    .action-panel-open + #inventoryAnalysisPanel .panel-container,
    .action-panel-open + .analysis-panel-closed {
        display: none !important;
    }

    .action-panel-open,
    .mb-panel.panel-right.open {
            border-left: 1px solid $gray;
    }
}

.mb-panel.panel-right {
    width: 325px;
    background-color: white;
    @include media-breakpoint-down(md) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100% !important;
        height: 100px;
        z-index: 2;

        &.panel-active .panel-footer {
            display: flex !important;
            position: relative;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            background-color: $gray;
            gap: 0.5rem;

            button:first-child {
                margin-right: 16px;
            }

        }

        &.mobile-open {
            top: 0;
            z-index: 70101;
            bottom: initial;
            height: 100%;

            .panel-footer {
                background-color: $white;

                button {
                    display: none;
                }
            }
        }

        &:not(.mobile-open) {

            .panel-header,
            .panel-content {
                display: none;
            }
        }

        .panel-header-close {
            display: initial !important;
        }

        .panel-header {
            cursor: pointer !important;
        }

        .panel-content {
            height: 100%;
            overflow: scroll;
        }
    }

    &:not(.open):not(.panel-active) {
        width: 0;
        display: none;

        &.closeable {
            display: block;
            width: 58px;
        }
    }

    &:not(.closeable) {

        .panel-header-close {
            display: none;
        }

        .panel-header {
            cursor: initial;
        }
    }

    .panel-header,
    .panel-header-toggle-btn {
        flex:1;
        background-color: $blue;
        color: $white !important;
    }

    .panel-footer {
        display: none;

        button {
            vertical-align: middle;
        }
    }
}


.shrink-actions {

    .toggle-responsive-actions.d-md-none {
        display: block !important;
    }

    .toggle-responsive-action.d-md-inline-block {
        display: none !important;
    }
}

/* ----- END RIGHT / ACTION PANEL ----- */

/* ----- START ANALYSIS PANEL ----- */

.mb-panel.panel-container {
    @include media-breakpoint-down(md) {
        position: fixed;
        left: 0;
        top: 0;
        height: 95%;
        z-index: 1;
        border: none;
        width: 325px;

        .panel-content {
            display: block;
            height: 100%;
            overflow: scroll;
        }

        &:not(.mobile-open) {
            position: relative;
            width: 0 !important;;

            .panel-header {
                z-index: 0;
                display: none;
            }

            .panel-header-internal {
                z-index: 1;
                right: 0 !important;
            }

            .panel-header-text,
            .panel-header-close {
                display: none;
            }

            .panel-content {
                display: none;
            }
        }

        &.mobile-open {
            z-index: 72000;
            width: 100% !important;
        }

        &:not(.open):not(.mobile-open) {
            .panel-header {
                display: none;
            }
        }
    }

    .panel-content {
        padding: 16px;

        #cartPanel & {
            padding: 0;
        }
    }

    .panel-header-internal {
        right: 0;
        border-left: 1px solid $gray;
    }

    .panel-header,
    .mb-panel-action-container {
        border-left: 1px solid $gray;
    }
}

.panel-container {
    @extend .panel-left;

    .panel-content {
        border-left: 1px solid $gray;
    }
}


/* ----- END ANALYSIS PANEL ----- */


/* ----- React Migration Styles ----- */
.mb-checkbox-list {
    .mb-checkbox-collapse {
        cursor: pointer;
    }
}
